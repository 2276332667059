export enum LifespanOptions {
  VERY_VERY_SHORT = '30 seconds',
  VERY_SHORT = '5 minutes',
  SHORT = '30 minutes',
  SIX_HOURS = '6 hours',
  STANDARD = '1 day',
  COUPLE_DAYS = '2 days',
  WEEK = '1 week',
  PROLONGED = '4 weeks',
  LONG = '24 weeks',
  FIVE_YEARS = '5 years',
}
