import React, { ReactNode } from 'react';
import { SvgIcon } from '@mui/material';
import {
  TextFields as TextFieldsIcon,
  Checklist as ChecklistIcon,
  SubscriptionsOutlined as SubscriptionsOutlinedIcon,
  FormatColorText as FormatColorTextIcon,
  AddPhotoAlternateOutlined as AddPhotoAlternateOutlinedIcon,
  QrCodeScanner as QrCodeScannerIcon,
  ViewAgendaOutlined as ViewAgendaOutlinedIcon,
} from '@mui/icons-material';
import { isEqual } from 'lodash';
import { slugify } from '@workerbase/utils/slugify';
import {
  DocumentBuilderStepTypes,
  BuilderStepInputMediaAccepts,
  FlaggingCriteria,
  InputNumberOperators,
  InputChecklistOperators,
  IsSectionStep,
  BuilderStep,
  BuilderSection,
} from '@workerbase/domain/document';
import { validateInputNumber } from '@workerbase/utils/validateInputNumber';
import { FilterTypes, isFilterGroup } from '@workerbase/domain/common';
import { getCustomVariableSchema } from 'components/DocumentBuilderStep/components/BuilderStepSettingsCustomVariable';
import generateRandomId from 'utils/generateRandomId';
import { getDisplayCriteriaOptions } from 'components/DocumentBuilderStep/BuilderStepSection/hooks/useDisplayCriteriaOptions';
import { getSectionSettingsValidationSchema } from 'components/DocumentBuilderStep/BuilderStepSection/sectionSettings.schema';
import Icon123 from '../../assets/icons/123.svgr';
import { checklistSettingsFlaggingCriteriaOptionsMapper } from '../DocumentBuilderStep';
import {
  BuilderStepInputTextUI,
  BuilderSectionUIWithStepListItem,
  StepListItem,
  BuilderStepInputMediaUI,
  BuilderStepInputChecklistUI,
  BuilderStepInputScancodeUI,
  BuilderStepInfoTextUI,
  BuilderStepInfoMediaUI,
  BuilderStepInputNumberUI,
} from './types';
import { validationSchema as checklistSettingsSchema } from '../DocumentBuilderStep/BuilderStepInputChecklist/inputChecklistSettings.validationSchema';

export const flattenStepList = (steps: StepListItem[]) =>
  steps.reduce<StepListItem[]>((acc, step) => {
    if (IsSectionStep(step?.step)) {
      acc.push(step, ...step.step.steps);
    } else {
      acc.push(step);
    }

    return acc;
  }, []);

export const flattenSteps = (steps: (BuilderStep | BuilderSection)[]): (BuilderStep | BuilderSection)[] =>
  steps.reduce<(BuilderStep | BuilderSection)[]>((acc, step) => {
    if (IsSectionStep(step)) {
      acc.push(step, ...flattenSteps(step.steps));
    } else {
      acc.push(step);
    }

    return acc;
  }, [] as BuilderStep[]);

export const getStepIdsUsedInDisplayCriteria = (displayCriteria: BuilderSection['displayCriteria']): string[] => {
  if (!displayCriteria) {
    return [];
  }

  const allIds = displayCriteria.conditions.reduce<string[]>((acc, condition) => {
    if (isFilterGroup(condition)) {
      return [...acc, ...getStepIdsUsedInDisplayCriteria(condition)];
    }

    return condition.name ? [...acc, condition.name] : acc;
  }, []);

  return [...new Set(allIds)];
};

export const getStepDisplayCriteriaDiff = (
  newStep: BuilderStep | BuilderSectionUIWithStepListItem,
  oldStep: BuilderStep | BuilderSectionUIWithStepListItem,
) => {
  if (IsSectionStep(newStep) && IsSectionStep(oldStep)) {
    const newConditionedQuestionIds = getStepIdsUsedInDisplayCriteria(newStep.displayCriteria);
    const oldConditionedQuestionIds = getStepIdsUsedInDisplayCriteria(oldStep.displayCriteria);

    if (isEqual(newConditionedQuestionIds, oldConditionedQuestionIds)) {
      return { idsRemoved: undefined, idsAdded: undefined };
    }

    const displayCriteriaDiff = newConditionedQuestionIds.reduce<{
      idsRemoved: string[];
      idsAdded: string[];
    }>(
      (acc, newId) => {
        if (!oldConditionedQuestionIds.includes(newId)) {
          acc.idsAdded.push(newId);
        }
        return acc;
      },
      {
        idsRemoved: oldConditionedQuestionIds.filter((id) => !newConditionedQuestionIds.includes(id)),
        idsAdded: [],
      },
    );

    return displayCriteriaDiff;
  }

  return { idsRemoved: undefined, idsAdded: undefined };
};

export const generateUniqueSlug = ({
  name,
  existingSlugs = [],
}: {
  name: string;
  existingSlugs?: string[];
}): string => {
  let slug = slugify(name);

  // Make sure this generated slug is unique
  while (existingSlugs.indexOf(slug) !== -1) {
    const randomString = Math.random().toString(36).substr(2, 3);
    slug = `${slug}-${randomString}`;
  }

  return slug;
};

export const validateFlaggingInputNumberCriteriaCondtions = (
  conditions: FlaggingCriteria<InputNumberOperators>['conditions'] | undefined,
): boolean =>
  conditions?.some((item) => {
    if (item.type === FilterTypes.GROUP) {
      return validateFlaggingInputNumberCriteriaCondtions(item.conditions);
    }

    return item.value === '' || !item.value;
  }) || false;

export const validateFlaggingInputChecklistCriteriaCondtions = (
  conditions: FlaggingCriteria<InputChecklistOperators>['conditions'] | undefined,
): boolean =>
  conditions?.some((item) => {
    if (item.type === FilterTypes.GROUP) {
      return validateFlaggingInputChecklistCriteriaCondtions(item.conditions);
    }

    return item.name === '' || !item.name;
  }) || false;

export const validateSectionDisplayCriteria = (
  stepSection: BuilderSectionUIWithStepListItem,
  steps: StepListItem[],
): boolean => {
  const displayCriteriaOptions = getDisplayCriteriaOptions(steps, stepSection._id);
  const isSettingsFormValid = getSectionSettingsValidationSchema(
    displayCriteriaOptions,
    stepSection.steps,
    stepSection._id,
  ).isValidSync({
    sectionName: stepSection.title,
    hasDisplayCriteria: !!stepSection.displayCriteria,
    displayCriteria: stepSection.displayCriteria,
    isCustomVariableName: stepSection.isCustomVariableName,
    variableName: stepSection.variableName,
  });

  return stepSection.steps.length === 0 || !isSettingsFormValid;
};

// eslint-disable-next-line complexity
export const stepHasError = (step: BuilderStep | BuilderSectionUIWithStepListItem, steps: StepListItem[]): boolean => {
  const customVariableSchema = getCustomVariableSchema(step._id, steps);
  if (step.type !== DocumentBuilderStepTypes.InfoMedia && !step.title) {
    return true;
  }

  switch (step.type) {
    case DocumentBuilderStepTypes.InfoMedia:
      return !step.media;
    case DocumentBuilderStepTypes.InputText: {
      const typedStep = step as BuilderStepInputTextUI;
      const maxCharacters = typedStep.maxCharacters;

      return (!!maxCharacters && String(typedStep.maxCharacters) === '') || !customVariableSchema.isValidSync(step);
    }
    case DocumentBuilderStepTypes.ContainerSection:
      return validateSectionDisplayCriteria(step, steps);
    case DocumentBuilderStepTypes.InputMedia:
      return !(step as BuilderStepInputMediaUI).accept.length || !customVariableSchema.isValidSync(step);
    case DocumentBuilderStepTypes.InputNumber:
      return (
        [String(step.decimalDigits), String(step.integerDigits)].includes('') ||
        !validateInputNumber(step.defaultValue, step.integerDigits, step.decimalDigits) ||
        validateFlaggingInputNumberCriteriaCondtions(step.flaggingCriteria?.conditions) ||
        !customVariableSchema.isValidSync(step)
      );
    case DocumentBuilderStepTypes.InputChecklist: {
      const checkStep = step;
      const flaggingCriteriaOptions = checklistSettingsFlaggingCriteriaOptionsMapper(checkStep.options);

      const isValidForm = checklistSettingsSchema(checkStep._id, steps, flaggingCriteriaOptions).isValidSync({
        ...checkStep,
        hasFlaggingCriteria: !!checkStep.flaggingCriteria,
      });

      return checkStep.options.length < 2 || ['', '0'].includes(String(checkStep.minSelect)) || !isValidForm;
    }
    case DocumentBuilderStepTypes.InputScancode: {
      const inputScanCode = step as BuilderStepInputScancodeUI;
      return (
        (!inputScanCode.title && inputScanCode.conditions.some((item) => !item.value)) ||
        !customVariableSchema.isValidSync(step)
      );
    }
    case DocumentBuilderStepTypes.InfoText:
    default:
      return false;
  }
};

export const StepColorMapper: Record<DocumentBuilderStepTypes, string> = {
  [DocumentBuilderStepTypes.InfoText]: '#2660E6',
  [DocumentBuilderStepTypes.InfoMedia]: '#2660E6',
  [DocumentBuilderStepTypes.InputChecklist]: '#FE812A',
  [DocumentBuilderStepTypes.InputText]: '#1B90FF',
  [DocumentBuilderStepTypes.InputMedia]: '#AF4BE8',
  [DocumentBuilderStepTypes.InputNumber]: '#66BB6A',
  [DocumentBuilderStepTypes.InputScancode]: '#26C6DA',
  [DocumentBuilderStepTypes.ContainerSection]: '#78909C',
};

export const StepIconMapper: Record<DocumentBuilderStepTypes, ReactNode> = {
  [DocumentBuilderStepTypes.InfoText]: <TextFieldsIcon />,
  [DocumentBuilderStepTypes.InfoMedia]: <SubscriptionsOutlinedIcon />,
  [DocumentBuilderStepTypes.InputChecklist]: <ChecklistIcon />,
  [DocumentBuilderStepTypes.InputText]: <FormatColorTextIcon />,
  [DocumentBuilderStepTypes.InputMedia]: <AddPhotoAlternateOutlinedIcon />,
  [DocumentBuilderStepTypes.InputNumber]: <SvgIcon component={Icon123} inheritViewBox />,
  [DocumentBuilderStepTypes.InputScancode]: <QrCodeScannerIcon />,
  [DocumentBuilderStepTypes.ContainerSection]: <ViewAgendaOutlinedIcon />,
};

const NESTED_STEPS_ID_DELIMITER = '.';

export const createNestedStepId = ({ parentId, stepId }: { parentId: string; stepId: string }): string =>
  `${parentId}${NESTED_STEPS_ID_DELIMITER}${stepId}`;

export const getNestedStepIds = (id: string): { stepId: string; nestedStepId?: string; isNestedStepId: boolean } => {
  const [stepId, nestedStepId] = id.split(NESTED_STEPS_ID_DELIMITER);
  return { stepId, nestedStepId, isNestedStepId: nestedStepId !== undefined };
};
interface GenerateNewStepProps {
  stepType: DocumentBuilderStepTypes;
  existingSlugs?: string[];
}
export const generateNewStep = ({
  stepType,
  existingSlugs,
}: GenerateNewStepProps): BuilderStep | BuilderSectionUIWithStepListItem => {
  const stepBase = {
    _id: Date.now().toString(),
    variableName: generateUniqueSlug({ name: stepType, existingSlugs }),
    isCustomVariableName: false,
    type: stepType,
    title: '',
    description: '',
  };

  switch (stepType) {
    case DocumentBuilderStepTypes.InfoText:
      return stepBase as BuilderStepInfoTextUI;
    case DocumentBuilderStepTypes.InfoMedia:
      return { ...stepBase } as BuilderStepInfoMediaUI;
    case DocumentBuilderStepTypes.InputNumber:
      return {
        ...stepBase,
        integerDigits: 2,
        decimalDigits: 2,
        defaultValue: 0,
        required: true,
      } as BuilderStepInputNumberUI;
    case DocumentBuilderStepTypes.InputChecklist:
      return {
        ...stepBase,
        options: [
          { label: '', value: '', _id: generateRandomId() },
          { label: '', value: '', _id: generateRandomId() },
          { label: '', value: '', _id: generateRandomId() },
        ],
        minSelect: 1,
        multiple: false,
        required: true,
      } as BuilderStepInputChecklistUI;
    case DocumentBuilderStepTypes.InputMedia:
      return {
        ...stepBase,
        accept: [
          BuilderStepInputMediaAccepts.Image,
          BuilderStepInputMediaAccepts.Audio,
          BuilderStepInputMediaAccepts.Video,
        ],
        multiple: false,
        max: null,
        required: true,
      } as BuilderStepInputMediaUI;
    case DocumentBuilderStepTypes.InputScancode:
      return {
        ...stepBase,
        conditions: [],
        allowManualInput: true,
        required: true,
      } as BuilderStepInputScancodeUI;
    case DocumentBuilderStepTypes.InputText:
      return {
        ...stepBase,
        maxCharacters: null,
        required: true,
      } as BuilderStepInputTextUI;
    case DocumentBuilderStepTypes.ContainerSection:
      return {
        ...stepBase,
        steps: [],
        displayCriteria: null,
        roles: [],
        title: 'Section name',
      } as BuilderSectionUIWithStepListItem;
    default:
      throw new Error(`${stepType} not implemented yet`);
  }
};

export const getExistingStepSlugs = (steps: StepListItem<BuilderStep | BuilderSectionUIWithStepListItem>[]) =>
  steps.map(({ step }) => step.variableName);

type StepOrListItem = BuilderStep | BuilderSection | StepListItem;
type StepsArray = (BuilderStep | BuilderSection)[] | StepListItem[];

const isStepListItem = (item: StepOrListItem): item is StepListItem => 'step' in item && 'meta' in item;
const getStep = (item: StepOrListItem) => (isStepListItem(item) ? item.step : item);

export const slugifySteps = <T extends StepsArray>(items: T, forceSlugifyIds: string[] = []): T => {
  const slugMap: string[] = (items as StepOrListItem[])
    .filter((item) => {
      const step = getStep(item);
      return step.isCustomVariableName && !forceSlugifyIds.includes(step._id);
    })
    .map((item) => getStep(item).variableName);

  return items.map((item) => {
    const step = getStep(item);
    const slug =
      step.isCustomVariableName && !forceSlugifyIds.includes(step._id)
        ? step.variableName
        : generateUniqueSlug({
            name: step.title || step.type,
            existingSlugs: slugMap,
          });

    if (IsSectionStep(step)) {
      const section = step;
      const newSection = {
        ...section,
        variableName: slug,
        steps: slugifySteps(section.steps, forceSlugifyIds),
        // steps: slugifySteps(section.steps, [...forceSlugifyIds, step._id]),
      };

      slugMap.push(slug);
      return isStepListItem(item) ? { step: newSection, meta: item.meta } : newSection;
    }

    slugMap.push(slug);
    return isStepListItem(item)
      ? { step: { ...step, variableName: slug }, meta: item.meta }
      : { ...step, variableName: slug };
  }) as T;
};
